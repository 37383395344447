<template>
  <div class="user-logged">
    <div class="user-logged__group-balance">
      <div class="balance">{{ $formatAmountUnit(store.currentUser.balance, 1) }} K</div>
      <BaseButton
        id="header-deposit-btn"
        class="base-button--btn-primary btn-deposit"
        @click="onNavDeposit()"
      >
        Nạp
      </BaseButton>
    </div>
    <div class="user-logged__group-avatar" @click="openMenuAccount()">
      <BaseImg
        class="avatar"
        alt="user avatar"
        :src="`/assets/images/components/desktop/avatar/${store.currentUser?.avatar}.webp`"
      />
      <BaseImg
        class="hamburger"
        alt="hamburger"
        :src="`${PATH_AVATAR_URL}hamburger.svg`"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { useAppStore } from '~/store/app'
import { PATH_AVATAR_URL } from '~/constants/path'
import { useAccountStore } from '~~/store/account'
import { useToggleMenu } from '~/composables/useToggleMenu'
import BaseButton from '~/components/common/base-button.vue'
import BaseImg from '~/components/common/base-img.vue'
const { $pinia, $formatAmountUnit } = useNuxtApp()
const store = useAppStore($pinia)
const { setDepositFrom } = store
const accountStore = useAccountStore()
const { toggleMenuAccount, toggleMenuDeposit } = useToggleMenu()

const onNavDeposit = () => {
  setDepositFrom('deposit')
  toggleMenuDeposit()
}

const openMenuAccount = () => {
  toggleMenuAccount()
}
</script>
<style scoped lang="scss" src="public/assets/scss/components/mobile/header/user-logged.scss" />
